.battery {
    border: 3px solid #ddd;
    min-width: 28px;
    height: 18px;
    padding: 2px;
    border-radius: 4px;
    position: relative;
    margin: 0px 0;
    #transform: rotate(90deg);
}

.battery::after {
    content: '';
    height: 18px;
    width: 3px;
    background: #ddd;
    display: block;
    position: absolute;
    right: -5px;
    top: 2px;
    border-radius: 0px 4px 4px 0px;
}

.battery::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid black;
    border-radius: 2px;
}

.battery.empty::before {
    border: 1px solid #e81309;
}
.battery.empty::after {
    background: #777;
}
.battery.empty {
    border: 3px solid #777;
}
.battery-level {
    background: #30b455;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 22px;
}

.battery-color-empty {
    background-color: #e81309;
}

.battery-color-low {
    background-color: #e81309;
}

.battery-color-nearToMin {
    background-color:  #f17f19;
}

.battery-color-min {
    background-color: #EFAF13;
}

.battery-color-nearToTop {
    background-color: #88b136;
}

.battery-color-top {
    background-color: #30b455;
}

.battery-color-overTop {
    background-color: #B506B8;
}

.battery-level.battery-color-overTop {
    background-color: #30b455;
}
.battery-level.battery-color-overTop:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cg%3E%3C%2Fg%3E%20%3Cpath%20fill%3D%22%23b506b8%22%20d%3D%22M17.927%2012l2.68-10.28c0.040-0.126%200.060-0.261%200.060-0.4%200-0.726-0.587-1.32-1.314-1.32-0.413%200-0.78%200.187-1.019%200.487l-13.38%2017.353c-0.18%200.227-0.287%200.513-0.287%200.827%200%200.733%200.6%201.333%201.333%201.333h8.073l-2.68%2010.28c-0.041%200.127-0.060%200.261-0.060%200.4%200.001%200.727%200.587%201.32%201.314%201.32%200.413%200%200.78-0.186%201.020-0.487l13.379-17.353c0.181-0.227%200.287-0.513%200.287-0.827%200-0.733-0.6-1.333-1.333-1.333h-8.073z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E'); background-repeat: no-repeat;
    background-size: 14px;
    height: 14px;
    width: 14px;
    left: 2px;
    top: 4px;
    content: '';
    position: relative;
    display: block;
}
.charged {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cg%3E%3C%2Fg%3E%20%3Cpath%20fill%3D%22%23b506b8%22%20d%3D%22M17.927%2012l2.68-10.28c0.040-0.126%200.060-0.261%200.060-0.4%200-0.726-0.587-1.32-1.314-1.32-0.413%200-0.78%200.187-1.019%200.487l-13.38%2017.353c-0.18%200.227-0.287%200.513-0.287%200.827%200%200.733%200.6%201.333%201.333%201.333h8.073l-2.68%2010.28c-0.041%200.127-0.060%200.261-0.060%200.4%200.001%200.727%200.587%201.32%201.314%201.32%200.413%200%200.78-0.186%201.020-0.487l13.379-17.353c0.181-0.227%200.287-0.513%200.287-0.827%200-0.733-0.6-1.333-1.333-1.333h-8.073z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E'); background-repeat: no-repeat;
    background-size: 16px;
    height: 16px;
    width: 16px;
    margin-left: 4px;
}
