.chatBubble {
    position: relative;
    background-color: var(--brainz-dark);
    border-radius: 20px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    margin: 10px 15px 0px;
    max-width: 600px;
    color: var(--brainz-color-text);
    cursor: pointer;
}
.chatBubbleSpacer {
    min-width: 80px;
}

.dateHeading {
    font-weight: 400;
    font-size: 14px;
    align-self: center;
    background-color: var(--brainz-dark);
    border: 1px solid var(--brainz-darkest);
    border-radius: 6px;
    padding: 5px;
    margin-top: 10px;
}

.chatMessageSecondary {
    font-size: 12px;
    margin-top: 5px;
    padding-bottom: 3px;
}

.chatMessage {
    display: flex;
    margin-top: 10px;
    font-size: 14px;
}

.directionSend {
    justify-content: end;
    flex-direction: row-reverse;
}

.chatBubble.directionSend:before {
    border-radius: 0px 0px 20px;
    left: auto;
    right: -7px;
}
.chatBubble:before {
    content: "";
    position: absolute;
    border: 0 solid transparent;
    border-bottom: 7px solid var(--brainz-dark);
    border-radius: 0 0 0 20px;
    width: 12px;
    height: 12px;
    top: -5px;
    left: -7px;
}

.chatContent {
    overflow-wrap: break-word;
    overflow: hidden;
}
