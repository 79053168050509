.root {
    cursor: text;
    padding: 5px;
}

.dark:hover {
    background-color: var(--brainz-darker);
}

.light {
    cursor: text;
}
.light:hover {
    background-color: #56585B;
    color: var(--brainz-darkLight);
}
